<template>
  <div class="ticketMain">
    <div class="ticketLeft">
      <head-layout head-title="作业票流程"></head-layout>
      <div class="ticketStep">
        <el-steps direction="vertical" :active="ticketStep">
          <el-step title="申请" @click.native="runJump('draft')"></el-step>
          <el-step title="安全交底" @click.native="runJump('disclose')"></el-step>
          <el-step title="安全监管" @click.native="runJump('supervise')"></el-step>
          <el-step title="验收确认" @click.native="runJump('confirm')"></el-step>
          <el-step title="完成" @click.native="runJump('confirm')"></el-step>
        </el-steps>
      </div>
    </div>
    <div class="ticketRight">
      <div class="ticketHead">
        <head-layout head-title="作业票基本信息" :head-btn-options="headBtnOptions" @head-forward="handleForward"
                     @head-save="headSave('saveData')" @head-cancel="headCancel" @head-refer="headRefer"></head-layout>
        <el-button type="primary" class="ticketHeadBtn">吊装安全作业</el-button>
        <el-form :model="dataForm" label-width="100px" class="ticketHeadCode">
          <el-form-item label="作业票编号:" prop="ticketCode">
            <el-input v-model="dataForm.ticketCode" placeholder="请输入作业票编号" style="width: 300px"
                      :disabled="true"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="ticketContent">
        <div class="ticketForm" id="draft">
          <el-form :model="dataForm" label-width="140px" :rules="rules" ref="formLayout"
                   :disabled="['view', 'flow', 'sign', 'workflow'].includes(type)">
            <el-row>
              <el-col :span="8">
                <el-form-item label="作业申请单位:" prop="requirOrg">
                  <el-input v-model="dataForm.requirOrg" placeholder="请选择/输入作业申请单位" maxlength="255" show-word-limit>
                    <el-button slot="append" icon="el-icon-search" @click="openDeptBelow('requirOrg')"></el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="作业单位:" prop="workOrg">
                  <el-input v-model="dataForm.workOrg" placeholder="请选择/输入作业单位" maxlength="255" show-word-limit>
                    <el-button slot="append" icon="el-icon-search" @click="openDeptBelow('workOrg')"></el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="作业申请时间:" prop="requirTime">
                  <el-date-picker v-model="dataForm.requirTime" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                                  value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择作业申请时间">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="吊装地点:" prop="hoistSite">
                  <el-input v-model="dataForm.hoistSite" placeholder="请输入吊装地点" maxlength="255" show-word-limit>
                    <el-button slot="append" icon="el-icon-search" @click="operatingLocation()"></el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="吊具名称:" prop="hoistEquipment">
                  <el-input v-model="dataForm.hoistEquipment" placeholder="请输入吊具名称" maxlength="255"
                            show-word-limit></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="吊物内容:" prop="hoistContent">
                  <el-input v-model="dataForm.hoistContent" placeholder="请输入吊物内容" maxlength="255"
                            show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <!-- <el-col :span="8">
            <el-form-item label="吊装作业人:" prop="userName">
              <el-input v-model="dataForm.userName" placeholder="请选择吊装作业人" maxlength="255" show-word-limit
                        :disabled="type == 'view' || (!['edit', 'add','sign'].includes(type) && businessStatus != 'PREPARE')">
                <el-button slot="append" icon="el-icon-search" @click="openUser('userName')"
                           :disabled="type == 'view' || (!['edit', 'add','sign'].includes(type) && businessStatus != 'PREPARE')"></el-button>
              </el-input>
            </el-form-item>
          </el-col> -->
              <el-col :span="8">
                <el-form-item label="司索人:" prop="solicitorUserName">
                  <el-input v-model="dataForm.solicitorUserName" placeholder="请选择/输入司索人" maxlength="64" show-word-limit>
                    <el-button slot="append" icon="el-icon-search" @click="openUser('solicitorUserName')"></el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="监护人:" prop="guardianName">
                  <el-input v-model="dataForm.guardianName" placeholder="请选择/输入监护人" maxlength="255" show-word-limit>
                    <el-button slot="append" icon="el-icon-search" @click="openUser('guardianName')"></el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="指挥人员:" prop="conductorUserName">
                  <el-input v-model="dataForm.conductorUserName" placeholder="请选择/输入指挥人员" maxlength="64"
                            show-word-limit>
                    <el-button slot="append" icon="el-icon-search" @click="openUser('conductorUserName')"></el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="吊物质量(t):" prop="hoistWeight">
                  <el-input-number v-model="dataForm.hoistWeight" :min="0" :max="999999.99" :precision="2" :step="0.01"
                                   placeholder="请输入吊物质量" :controls="false" style="width: 100%;">
                    <template slot="append">t</template>
                  </el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="吊物级别:" prop="hoistLevel">
                  <el-radio-group v-model="dataForm.hoistLevel">
                    <el-radio :label="item.dictKey" v-for="(item, index) in hoistLevelOption" :key="index"
                              style="margin: 8px">
                      {{ item.dictValue }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="安全交底人:" prop="securityDisclosureName">
                  <el-input v-model="dataForm.securityDisclosureName" placeholder="请选择/输入安全交底人" readonly maxlength="255"
                            show-word-limit>
                    <el-button slot="append" icon="el-icon-search"
                               @click="openUser('securityDisclosureName')"></el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <!--              <el-col :span="8">-->
              <!--                <el-form-item label="安全检查:" prop="securityCheck">-->
              <!--                  <el-select v-model="dataForm.securityCheck" placeholder="请选择安全检查">-->
              <!--                    <el-option v-for="item in securityCheckOption" :key="item.id" :label="item.typeName"-->
              <!--                      :value="item.id">-->
              <!--                    </el-option>-->
              <!--                  </el-select>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
            </el-row>
            <el-row>
              <el-col :span="14">
                <el-form-item label="吊装作业时间:" prop="timeInterval">
                  <el-date-picker v-model="dataForm.timeInterval" type="datetimerange" format="yyyy-MM-dd HH:mm"
                                  value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
                                  end-placeholder="结束日期" style="width: 100%">
                  </el-date-picker>
                </el-form-item>
              </el-col>

            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="风险辨识结果:" prop="wtRiskList">
                  <el-checkbox-group v-model="dataForm.wtRiskList">
                    <el-checkbox :label="item.optionCode" v-for="(item, index) in riskIdentResults" :key="index"
                                 @change="wtRiskChanger(item, '')">
                      <div v-if="item.optionCode == 'other'">
                        其他:
                        <el-input v-model="wtRiskOther" placeholder="请输入其他" maxlength="255" show-word-limit
                                  :disabled="wtRiskThow || (type == 'workflow' && businessStatus != 'PREPARE')"
                                  @change="wtRiskChanger(item, 'input')"></el-input>
                      </div>
                      <div v-else>
                        {{ item.optionName }}
                      </div>
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="辨识人签字:" prop="recognizePeople">
                  <img class="signatureImg" :src="dataForm.recognizePeople" v-if="dataForm.recognizePeople !== ''">
                  <el-button size="small" v-else @click="openSignature(99, 'recognizePeople')">请签名
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="人员审核:">
                  <el-tag size="medium" :key="index" v-for="(item, index) in examPeopleList"
                          :closable="!['view', 'flow', 'sign', 'workflow'].includes(type)"
                          :disable-transitions="false" style="margin-right: 12px;"
                          @close="deleteExamPeople(item, index)">
                    {{ item.name }}
                  </el-tag>
                  <el-button type="primary" size="mini" @click="addExamPeople">增加</el-button>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="经理审核:">
                  <el-tag size="medium" :key="index" v-for="(item, index) in examManageList"
                          :closable="!['view', 'flow', 'sign', 'workflow'].includes(type)"
                          :disable-transitions="false" style="margin-right: 12px;"
                          @close="deleteExamManage(item, index)">
                    {{ item.name }}
                  </el-tag>
                  <el-button type="primary" size="mini" @click="addExamManage">增加</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="ticketSecure">
          <head-layout head-title="吊装作业人" @head-add="addOperator" :head-btn-options="operatorHeadBtn"></head-layout>
          <div class="gasTable">
            <el-table :data="wtUserList" border size="medium" style="width: 100%">
              <el-table-column label="序号" type="index" align="center" width="50">
              </el-table-column>
              <el-table-column prop="userName" align="center" label="人员姓名">
              </el-table-column>
              <el-table-column prop="userDept" align="center" label="所属组织">
              </el-table-column>
              <el-table-column prop="userLicense" align="center" label="证书编号">
              </el-table-column>
              <el-table-column label="操作" align="center" width="100" v-if="['edit', 'add'].includes(type)">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="deleteOperator(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="ticketGas">
          <head-layout head-title="安全监管人" :head-btn-options="superviseHeadBtn" @head-add="addSupervise"></head-layout>
          <div class="gasTable">
            <el-table :data="safetySupervisorList" border size="medium" style="width: 100%">
              <el-table-column label="序号" type="index" align="center" width="50">
              </el-table-column>
              <el-table-column prop="conservatorName" align="center" label="监管人名称">
              </el-table-column>
              <el-table-column label="操作" align="center" width="100" v-if="['edit', 'add'].includes(type)">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="deleteSupervise(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="ticketSecure">
          <head-layout head-title="安全措施" @head-add="addSecure" :head-btn-options="secureHeadBtn"></head-layout>
          <div class="gasTable">
            <el-table :data="wtSecurityPreventionList" border size="medium" style="width: 100%">
              <el-table-column label="序号" type="index" align="center" width="50">
              </el-table-column>
              <el-table-column prop="securityContent" align="center" label="安全措施">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.securityContent" placeholder="请输入安全措施"
                            :disabled="['view', 'flow', 'sign', 'workflow'].includes(type)" type="textarea"
                            :autosize="{ minRows: 1, maxRows: 6 }" maxlength="255" show-word-limit></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="invovedFlag" align="center" width="100" label="是否涉及">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.invovedFlag" active-value="1" inactive-value="0" active-text="是"
                             v-if="scope.row.type != 'other'"
                             :disabled="['view', 'flow', 'sign', 'workflow'].includes(type)"
                             inactive-text="否">
                  </el-switch>
<!--                  <div v-else>
                    <img class="signatureImg" :src="scope.row.confirmUser" v-if="scope.row.confirmUser != ''">
                    <el-button size="small" @click="openSignature(scope.$index, 'wtSecurityPreventionList')"
                               v-if="scope.row.confirmUser == '' && ['edit', 'add'].includes(type)">
                      签名
                    </el-button>
                  </div>-->
                </template>
              </el-table-column>
              <!-- <el-table-column prop="confirmUser" align="center" label="确认人" width="200">
                <template slot-scope="scope">
                  <img class="signatureImg" :src="scope.row.confirmUser" v-if="scope.row.confirmUser != ''">
                  <el-button size="small" v-else @click="openSignature(scope.$index, 'wtSecurityPreventionList')"
                    v-if="(type != 'view' && (['edit', 'add', 'sign'].includes(type) || businessStatus == 'PREPARE')) && scope.row.confirmUser == ''">
                    签名
                  </el-button>
                </template>
              </el-table-column> -->
              <el-table-column label="操作" align="center" width="100" v-if="['edit', 'add'].includes(type)">
                <template slot-scope="scope" v-if="scope.row.type != 'other'">
                  <el-button type="text" size="small" @click="deleteSecure(scope.$index, wtSecurityPreventionList)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!--安全措施签名-->
        <div class="ticketDisclose">
          <securitySignature ref="securitySignature" :securityType="$route.query.type"
                             :businessStatus="businessStatus"
                             @deleteImg="deleteImgSecure"
                             @getSecuritySignature="getSecuritySignature">
          </securitySignature>
        </div>
        <!--安全交底人-->
        <div class="ticketDisclose">
          <securityDisclosure ref="securityDisclosure" :securityType="$route.query.type"
                              :businessStatus="businessStatus">
          </securityDisclosure>
        </div>
        <!-- 作业票审批 -->
        <div class="ticketGas" v-if="processFlowList.length != 0">
          <head-layout head-title="作业票审批"></head-layout>
          <div class="gasTable">
            <el-table :data="processFlowList" border size="medium" style="width: 100%">
              <el-table-column label="序号" type="index" align="center" width="50">
              </el-table-column>
              <el-table-column prop="assigneeName" align="center" label="审批人">
              </el-table-column>
              <el-table-column prop="comments" align="center" label="审批意见">
                <template slot-scope="scope">
                  <el-tag type="danger" v-if="scope.row.comments.length != 0">{{
                      scope.row.comments[0].message
                    }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="historyActivityType" align="center" label="审批结果">
                <template slot-scope="scope">
                  <el-tag>{{
                      scope.row.historyActivityType == "endEvent"
                        ? "结束"
                        : scope.row.historyActivityName || "未命名"
                    }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="endTime" align="center" label="审批时间">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 安全交底 -->
        <div class="ticketGas" id="disclose" v-if="['DISCLOSED', 'SUPERVISE', 'CHECKACCEPT', 'FINISHED'].includes(
          businessStatus
        ) && ['supervise', 'safeConfessor', 'respUser', 'drafter'].includes(characters)">
          <head-layout head-title="安全交底" :head-btn-options="discloseHeadBtn" @head-add="addDisclose"></head-layout>
          <div class="gasTable">
            <el-table :data="securityDisclosureList" border size="medium" style="width: 100%">
              <el-table-column label="序号" type="index" align="center" width="50">
              </el-table-column>
              <el-table-column prop="disclosureName" align="center" label="交底人">
              </el-table-column>
              <el-table-column prop="documentUrl" align="center" label="相关文件">
                <template slot-scope="scope" v-if="scope.row.documentUrl != ''">
                  <el-tag @click="downloadFile(item)" style="cursor: pointer;"
                          v-for="(item, index) in JSON.parse(scope.row.documentUrl)" :key="index">{{
                      item.name
                    }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="disclosureTime" align="center" label="日期时间">
              </el-table-column>
              <el-table-column label="操作" align="center" width="150">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="editDisclose(scope.row)"
                             v-if="type == 'flow' && characters == 'safeConfessor'">
                    编辑
                  </el-button>
                  <el-button type="text" size="small" @click="deleteDisclose(scope.row)"
                             v-if="type == 'flow' && characters == 'safeConfessor'">
                    删除
                  </el-button>
                  <el-button type="text" size="small" @click="viewDisclose(scope.row)">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 安全监管现场照片 -->
        <div class="ticketGas" id="supervise" v-if="
          ['SUPERVISE', 'CHECKACCEPT', 'FINISHED'].includes(businessStatus) &&
          ['supervise', 'safeConfessor', 'respUser', 'drafter'].includes(
            characters
          )
        ">
          <superviseImg ref="superviseImg" :disabled="type == 'flow' && characters == 'supervise'"></superviseImg>
        </div>
        <!-- 完工确认 -->
        <div class="ticketGas" id="confirm" v-if="
          ['CHECKACCEPT', 'FINISHED'].includes(businessStatus) &&
          ['supervise', 'safeConfessor', 'respUser', 'drafter'].includes(
            characters
          )
        ">
          <finishWorkImg ref="finishWorkImg" :disabled="type == 'flow' && characters == 'respUser'"></finishWorkImg>
        </div>
      </div>
    </div>
    <!--签名弹窗-->
    <el-dialog title="电子签名" :visible.sync="signatureShow" v-if="signatureShow" width="1000px">
      <signature @getSignatureImg="getSignatureImg"></signature>
    </el-dialog>
    <!--当前部门及一下弹窗-->
    <el-dialog title="部门选择" :visible.sync="deptBelowShow" width="1000px">
      <currentDepartmentBelow @getDeptBelow="getDeptBelow"></currentDepartmentBelow>
    </el-dialog>
    <!--当前部门及一下弹窗-->
    <el-dialog title="人员选择" :visible.sync="userShow" top="9vh" width="70%">
      <ProjectSelectUser v-if="userShow" @select-data="getUser"></ProjectSelectUser>
    </el-dialog>
    <!--安全交底表单弹窗-->
    <el-dialog title="安全交底" :visible.sync="discloseShow" top="9vh" width="40%">
      <el-form :model="discloseForm" :rules="discloseRules" ref="discloseForm" label-width="100px"
               :disabled="discloseType == 'view'">
        <el-form-item label="交底人" prop="disclosureName">
          <el-input v-model="discloseForm.disclosureName" placeholder="请选择交底人">
            <el-button slot="append" icon="el-icon-search" @click="openUser('disclosureName')"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="日期时间" prop="disclosureTime">
          <el-date-picker v-model="discloseForm.disclosureTime" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                          value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="交底内容" prop="remark">
          <el-input type="textarea" v-model="discloseForm.remark" placeholder="请输入交底内容"></el-input>
        </el-form-item>
        <el-form-item label="相关文件" prop="documentUrl">
          <el-upload action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
                     :disabled="discloseType == 'view'" :headers="headers" :on-success="discloseSuc"
                     :on-remove="discloseRem"
                     :on-preview="downloadFile" multiple :limit="3" :file-list="documentUrl">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="discloseShow = false">取 消</el-button>
        <el-button type="primary" @click="submitDisclse" v-if="discloseType != 'view'">确 定</el-button>
      </span>
    </el-dialog>
    <!--当前部门及一下多选弹窗-->
    <el-dialog title="人员选择" :visible.sync="userListShow" top="9vh" width="80%">
      <UserDeptMultipleDialog ref="userDeptMultipleDialog" v-if="userListShow" @select-all-data="getUserList"
                              @closeDia="userListShow = false">
      </UserDeptMultipleDialog>
    </el-dialog>
    <!--关键的其他特殊作业及安全作业票号-->
    <!--    <common-dialog-->
    <!--      dialogTitle="作业票清单"-->
    <!--      v-if="jobTicketDialogShow"-->
    <!--      width="60%"-->
    <!--      :showConfirmBtn=false-->
    <!--      @cancel="jobTicketDialogShow = false">-->
    <!--      <jobTicketDialog ref="jobTicketDialog" :ticketCode=dataForm.ticketCode @submitData="submitData"></jobTicketDialog>-->
    <!--    </common-dialog>-->
    <!--流程用户弹框-->
    <process-user-dialog ref="processUser" @getUserSelection="handleUserSelection"
                         @closeProcess="closeProcess"></process-user-dialog>
    <!--选择区域-->
    <riskArea class="riskAreaClass" ref="riskArea" :chooseType="'radio'" @areaSelect="areaSelect"></riskArea>
    <!--作业监管人上传的附件表-->
    <jobTicketImage ref="jobTicketImage" :title="'在线预览'"></jobTicketImage>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import signature from "@/views/components/signature";
import currentDepartmentBelow from "@/views/components/currentDepartmentBelow";
import UserDetpDialog from "@/views/business/jobSlip/jobTicketList/components/UserDetpDialog";
import {getDictionary} from "@/api/system/dictbiz";
import {
  templateCode,
  wtRiskAll,
  wtSecurityPreventionAll,
  myWtTicketDetail,
  removeWtSecurityPrevention,
  wtBaseHoist,
  wtBaseHoistRetransmission,
  removeWtUser,
  safetySupervisionRemove,
  securityDisclosureRemove, deleteImg
} from "@/api/workTicket";
import {list as security} from "@/api/safetyTtandard/jobActivity";
import CommonDialog from "@/components/CommonDialog";
import TemplateType from '@/views/business/jobSlip/jobSlipInventory/components/templateType';
import jobTicketDialog from "@/views/business/jobSlip/jobTicketList/jobTicketDialog";
import securityDisclosure from "@/views/business/jobSlip/jobTicketList/securityDisclosure";
import UserDeptMultipleDialog from "@/views/business/jobSlip/jobTicketList/components/UserDeptMultipleDialog.vue";
import {detail as processDetail, processList as getProcessList} from "@/api/plugin/workflow/process";
import {mapGetters} from "vuex";
import ProcessUserDialog from "@/views/business/components/processUserDialog";

import jobFlow from "@/views/business/jobSlip/jobTicketList/jobFlow";
import {getToken} from "@/util/auth";
import superviseImg from "@/views/business/jobSlip/jobTicketList/superviseImg";
import FinishWorkImg from "@/views/business/jobSlip/jobTicketList/finishWorkImg";
import riskArea from "@/views/business/jobSlip/jobTicketList/region.vue";
import jobTicketImage from "@/views/business/jobSlip/jobTicketList/jobTicketImage";
import ProjectSelectUser from "@/views/components/UserDeptDialog/projectSelectUser.vue";
import securitySignature from "@/views/business/jobSlip/jobTicketList/securitySignature.vue";

export default {
  components: {
    securitySignature,
    UserDeptMultipleDialog,
    HeadLayout,
    signature,
    currentDepartmentBelow,
    // UserDetpDialog,
    ProjectSelectUser,
    CommonDialog,
    TemplateType,
    jobTicketDialog,
    securityDisclosure,
    ProcessUserDialog,
    superviseImg,
    FinishWorkImg,
    riskArea,
    jobTicketImage
  },
  data() {
    return {
      processId: "",
      formProcess: {
        id: '',
        processId: '',
        data: {},
      },
      type: '',
      // jobTicketDialogShow: false,
      wtRiskThow: true,
      wtRiskOther: "",
      businessStatus: '',
      isSubmit: false,
      dataForm: {
        measureSignatureUrl: "",
        ticketCode: '',
        requirOrg: '',
        requirTime: null,
        recognizePeople: '',
        hoistSite: '',
        hoistEquipment: '',
        hoistContent: '',
        solicitorUserId: null,
        solicitorUserName: '',
        solicitorNickName: '',
        hoistLevel: '1',
        hoistWeight: null,
        conductorUserId: null,
        conductorUserName: '',
        conductorNickName: '',
        userId: null,
        userCode: '',
        userName: '',
        guardianId: null,
        guardianCode: '',
        guardianName: '',
        securityDisclosureName: "",
        securityDisclosureId: "",
        securityDisclosureCode: "",
        securityDisclosureDept: "",
        workOrg: '',
        relatedWt: '',
        startTime: null,
        endTime: null,
        securityCheck: '',
        wtRiskList: [],
        wtSecurityPreventionList: [],
        timeInterval: [],
        wtDisclosureList: [],
        subtableId: "",
      },
      kictectType: false,
      tableIndex: 0,
      tableName: '',
      userOpentype: '',
      deptOpenType: '',
      userListShow: false,
      signatureShow: false,
      deptBelowShow: false,
      userShow: false,
      hotWorkLevel: [],
      masterId: null,
      riskIdentResults: [],
      wtSecurityPreventionList: [],
      hoistLevelOption: [],
      securityCheckOption: [],
      rules: {
        timeInterval: [
          {required: true, message: '请选择吊装作业时间', trigger: ['blur']},
        ],
        requirOrg: [
          {required: true, message: '请选择/输入作业申请单位', trigger: ['blur', 'change']},
        ],
        requirTime: [
          {required: true, message: '请选择作业申请时间', trigger: ['blur', 'change']},
        ],
        workOrg: [
          {required: true, message: '请选择/输入作业单位', trigger: ['blur', 'change']},
        ],
        guardianName: [
          {required: true, message: '请选择/输入监护人', trigger: ['blur', 'change']},
        ],
        securityDisclosureName: [
          {
            required: true,
            message: "请选择/输入安全交底人",
            trigger: ["blur", "change"],
          },
        ],
        wtRiskList: [
          {required: true, message: '请选择风险辨识结果', trigger: ['blur', 'change']},
        ],
        hoistSite: [
          {required: true, message: '请输入吊装地点', trigger: ['blur', 'change']},
        ],
        hoistEquipment: [
          {required: true, message: '请输入吊装名称', trigger: ['blur', 'change']},
        ],
        hoistContent: [
          {required: true, message: '请输入吊物内容', trigger: ['blur', 'change']},
        ],
        userName: [
          {required: true, message: '请选择/输入吊装作业人', trigger: ['blur', 'change']},
        ],
        solicitorUserName: [
          {required: true, message: '请选择/输入司索人', trigger: ['blur', 'change']},
        ],
        conductorUserName: [
          {required: true, message: '请选择/输入指挥人员', trigger: ['blur', 'change']},
        ],
        hoistWeight: [
          {required: true, message: '请输入吊物质量(t)', trigger: ['blur', 'change']},
        ],
        hoistLevel: [
          {required: true, message: '请选择吊物级别', trigger: ['blur', 'change']},
        ],
        securityCheck: [
          {required: true, message: '请选择安全检查', trigger: ['blur', 'change']},
        ]
      },
      operatorSelect: false,
      wtUserList: [],

      characters: "",
      processInstanceId: '',
      processFlowList: [],
      safetySupervisorList: [],
      securityDisclosureList: [],
      discloseShow: false,
      discloseForm: {},
      discloseType: "add",
      discloseRules: {
        disclosureName: [
          {
            required: true,
            message: "请选择交底人",
            trigger: ["blur", "change"],
          },
        ],
        disclosureTime: [
          {
            required: true,
            message: "请选择日期时间",
            trigger: ["blur", "change"],
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入交底内容",
            trigger: ["blur", "change"],
          },
        ],
        // documentUrl: [
        //   {required: true, message: '请选择相关文件', trigger: ['blur', 'change']},
        // ]
      },
      documentUrl: [],
      examPeopleList: [],
      examManageList: []
    }
  },
  mixins: [jobFlow],
  computed: {
    ...mapGetters(["userInfo"]),
    headers: function () {
      return {"Sinoma-Auth": getToken()};
    },
    ticketStep() {
      let number = 0;
      if (
        ["PREPARE", "CONFIRMING", "CONFIRMING"].includes(this.businessStatus)
      ) {
        number = 1;
      }
      if (["DISCLOSED"].includes(this.businessStatus)) {
        number = 2;
      }
      if (["SUPERVISE"].includes(this.businessStatus)) {
        number = 3;
      }
      if (["CHECKACCEPT"].includes(this.businessStatus)) {
        number = 4;
      }
      if (["FINISHED"].includes(this.businessStatus)) {
        number = 5;
      }
      return number;
    },
    gasHeadBtn() {
      let result = [];
      if (["edit", "add"].includes(this.type)) {
        result.push({
          label: "新增",
          emit: "head-add",
          btnOptType: "add",
        });
      }
      return result;
    },
    secureHeadBtn() {
      let result = [];
      if (["edit", "add"].includes(this.type)) {
        result.push({
          label: "新增",
          emit: "head-add",
          btnOptType: "add",
        });
      }
      return result;
    },
    operatorHeadBtn() {
      let result = [];
      if (["edit", "add"].includes(this.type)) {
        result.push({
          label: "选择人员",
          emit: "head-add",
          btnOptType: "add",
        });
      }
      return result;
    },
    superviseHeadBtn() {
      let result = [];
      if (["edit", "add"].includes(this.type)) {
        result.push({
          label: "选择人员",
          emit: "head-add",
          btnOptType: "add",
        });
      }
      return result;
    },
    discloseHeadBtn() {
      let result = [];
      if (this.type == "flow" && this.businessStatus == "DISCLOSED") {
        result.push({
          label: "新增",
          emit: "head-add",
          btnOptType: "add",
        });
      }
      return result;
    },
    headBtnOptions() {
      let result = [];
      if (
        (this.type == "add" ||
          ["PREPARE", "DISCLOSED", "SUPERVISE", "CHECKACCEPT"].includes(
            this.businessStatus
          ) ||
          this.type == "sign") &&
        !["view", "workflow"].includes(
          this.type)
      ) {
        result.push({
          label: "保存",
          emit: "head-save",
          btnOptType: "save",
        });
      }
      if (['add', 'edit'].includes(this.type)) {
        result.push({
          label: "转发",
          emit: "head-forward",
          btnOptType: "forward",
        });
      }
      if (
        (this.type == "add" ||
          [
            "PREPARE",
            "CONFIRMING",
            "DISCLOSED",
            "SUPERVISE",
            "CHECKACCEPT",
          ].includes(this.businessStatus)) &&
        !["view", "workflow"].includes(
          this.type)
      ) {
        result.push({
          label: "提交",
          emit: "head-refer",
          loading: this.isSubmit,
          btnOptType: "refer",
        });
      }
      if (this.type != "workflow") {
        result.push({
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-cancel",
          type: "button",
          icon: "",
        });
      }
      return result;
    },
  },
  mounted() {
    let {type, id, businessStatus, characters, processInstanceId} = this.$route.query;
    this.type = type;
    this.characters = characters;
    this.businessStatus = businessStatus;
    this.processInstanceId = processInstanceId;


    this.getDictory();

    this.masterId = id;
    if (type == 'add') {
      this.dataForm.requirTime = this.getCurrentDateTime();
      //作业单位默认登陆人组织
      this.dataForm.requirOrg = this.userInfo.deptName;
      this.dataForm.workOrg = this.userInfo.deptName;
      this.getTemplateCode();
    }

    if (type != "add") {
      this.initData(id);
    }

    if (this.processInstanceId && this.processInstanceId != ''&& this.processInstanceId != "null") {
      this.getProcessDetail();
    }

    if (this.type == 'add' || this.type == 'edit') {
      // 获取可申请流程列表
      this.getProcess();
    }
  },
  methods: {
    //删除安全措施签名
    deleteImgSecure(){
      if (this.dataForm.id) {
        deleteImg(this.dataForm.ticketCode).then(res=>{
          this.dataForm.measureSignatureUrl = ""
        })
      }else {
        this.dataForm.measureSignatureUrl = ""
      }
    },
    getSecuritySignature(imgUrl) {
      this.dataForm.measureSignatureUrl = imgUrl;
    },
    // 流程详情
    getProcessDetail() {
      processDetail({processInsId: this.processInstanceId}).then((res) => {
        if (res.data.code == 200) {
          this.processFlowList = res.data.data.flow;
        }
      })
    },
    // 下载文件
    downloadFile(row) {
      this.$refs.jobTicketImage.jobTicketRow(row)
    },
    // 新增安全交底
    addDisclose() {
      this.discloseType = "add";
      this.discloseForm = {
        ticketCode: this.dataForm.ticketCode,
        remark: "",
        disclosureTime: this.getCurrentDateTime(),
        disclosureId: this.userInfo.id,
        disclosureName: this.userInfo.real_name,
        documentUrl: "",
      };
      this.documentUrl = [];
      this.discloseShow = true;
      this.$nextTick(() => {
        this.$refs.discloseForm.resetFields();
      });
    },
    // 编辑安全交底
    editDisclose(row) {
      this.discloseType = "edit";
      this.discloseForm = row;
      if (this.discloseForm.documentUrl == "") {
        this.documentUrl = [];
      } else {
        this.documentUrl = JSON.parse(this.discloseForm.documentUrl);
      }
      this.discloseShow = true;
    },
    // 删除安全交底
    deleteDisclose(row) {
      if (row.id) {
        this.$loading();
        securityDisclosureRemove(row.id).then((res) => {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.$loading().close();
        });
      } else {
        this.$message({
          type: "success",
          message: "操作成功",
        });
      }
      this.securityDisclosureList = this.securityDisclosureList.filter(
        (item) => item.id != row.id
      );
    },
    // 查看安全交底
    viewDisclose(row) {
      this.discloseType = "view";
      this.discloseForm = row;
      if (this.discloseForm.documentUrl == "") {
        this.documentUrl = [];
      } else {
        this.documentUrl = JSON.parse(this.discloseForm.documentUrl);
      }
      this.discloseShow = true;
    },
    // 保存安全交底
    submitDisclse() {
      this.$refs.discloseForm.validate((valid) => {
        if (valid) {

          if (this.documentUrl.length == 0) {
            this.documentUrl = "";
          } else {
            this.discloseForm.documentUrl = JSON.stringify(this.documentUrl);
          }
          if (this.discloseType == "add") {
            this.securityDisclosureList.push(this.discloseForm);
          }
          this.discloseShow = false;
        }
      });
    },
    // 上传成功
    discloseSuc(res) {
      if (res.code == 200) {
        this.documentUrl.push({
          name: res.data.originalName,
          url: res.data.link,
        });
      }
    },
    // 删除
    discloseRem(file) {
      this.documentUrl = this.documentUrl.filter(
        (item) => item.name != file.name
      );
    },
    // 新增安全监管人
    addSupervise() {
      this.userOpentype = "supervise";
      this.operatorSelect = true;
      this.userListShow = true;
      this.$nextTick(() => {
        this.$refs.userDeptMultipleDialog.selectionClear();
      });
      if (this.safetySupervisorList.length > 0) {
        let ids = [];
        this.safetySupervisorList.forEach((item) => {
          if (item.id && item.id > 0) {
            ids.push(item.id);
          }
        });
        if (ids.length > 0) {
          safetySupervisionRemove(ids.join(","));
        }
        this.safetySupervisorList = [];
      }
    },
    // 删除安全监管人
    deleteSupervise(item) {
      if (item.id && item.id > 0) {
        this.$loading();
        safetySupervisionRemove(item.id)
          .then((res) => {
            this.$message({
              type: "success",
              message: res.data.msg,
              duration: 1000,
            });
          })
          .finally(() => {
            this.$loading().close();
          });
      } else {
        this.$message({
          type: "success",
          message: "操作成功",
          duration: 1000,
        });
      }
      this.safetySupervisorList = this.safetySupervisorList.filter(
        (child) => child.conservatorId !== item.conservatorId
      );
    },
    //作业人员选择
    addOperator() {
      this.userOpentype = 'userName';
      this.operatorSelect = true;
      this.userListShow = true;
      this.$nextTick(() => {
        this.$refs.userDeptMultipleDialog.selectionClear();
      })
      if (this.wtUserList.length > 0) {
        let ids = []
        this.wtUserList.forEach(item => {
          if (item.id && item.id > 0) {
            ids.push(item.id)
          }
        })
        if (ids.length > 0) {
          removeWtUser(ids.join(","))
        }
        this.wtUserList = []
      }
    },
    deleteOperator(item) {
      if (item.id && item.id > 0) {
        this.$loading()
        removeWtUser(item.id).then((res) => {
          this.$message({
            type: "success",
            message: res.data.msg,
            duration: 1000,
          })
        }).finally(() => {
          this.$loading().close()
        });
      } else {
        this.$message({
          type: "success",
          message: "操作成功",
          duration: 1000,
        });
      }
      this.wtUserList = this.wtUserList.filter(child => child.userId !== item.userId)
    },
    areaSelect(row) {
      this.dataForm.hoistSite = row.areaName
    },
    //选择作业地点
    operatingLocation() {
      this.$refs.riskArea.init();
    },
    getCurrentDateTime() {
      let currentDate = new Date();

      let year = currentDate.getFullYear();
      let month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // 月份从0开始，需要加1
      let day = ('0' + currentDate.getDate()).slice(-2);
      let hours = ('0' + currentDate.getHours()).slice(-2);
      let minutes = ('0' + currentDate.getMinutes()).slice(-2);
      let seconds = ('0' + currentDate.getSeconds()).slice(-2);

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    getProcess() {
      // 获取可申请流程列表
      getProcessList(1, 99, {}).then((res) => {
        let process = res.data.data.records.filter(
          (item) => item.key == 'aSurname_job'
        )[0];
        this.processId = process.id;
        this.formProcess.processId = this.processId;
      });

    },
    // 风险辨识结果
    wtRiskChanger(row, wtRiskType) {
      if (wtRiskType == "input") {
        row.optionName = this.wtRiskOther
      } else {
        if (row.optionCode == "other") {
          if (this.wtRiskThow == false) {
            this.wtRiskThow = true
          } else {
            this.wtRiskThow = false
          }
        }
      }
    },
    // 查询详情数据
    async initData(id) {
      return new Promise((resolve, reject) => {
        myWtTicketDetail(id).then(res => {
          var data = res.data.data.data;
          this.businessStatus = data.businessStatus;
          if (data.startTime) {
            data.timeInterval = [data.startTime, data.endTime]
          }
          this.subtableId = data.id
          this.dataForm = data
          if(data.measureSignatureUrl){
            const measureSignature = {imgUrl: data.measureSignatureUrl}
            let measureSignatureUrlList = [];
            measureSignatureUrlList.push(measureSignature);
            this.$refs.securitySignature.initData(measureSignatureUrlList)
          }
          this.businessStatus = this.dataForm.businessStatus
          this.riskIdentResults = data.wtRiskList

          if (this.dataForm.examPeopleId != '') {
            this.examPeopleList = [];
            let examPeopleId = this.dataForm.examPeopleId.split(',')
            let examPeopleName = this.dataForm.examPeopleName.split(',')
            examPeopleId.forEach((item, index) => {
              this.examPeopleList.push({
                id: item,
                name: examPeopleName[index]
              })
            })
          }

          if (this.dataForm.examManageId != '') {
            this.examManageList = [];
            let examManageId = this.dataForm.examManageId.split(',')
            let examManageName = this.dataForm.examManageName.split(',')
            examManageId.forEach((item, index) => {
              this.examManageList.push({
                id: item,
                name: examManageName[index]
              })
            })
          }

          var wtRiskList = [];
          if (data.wtRiskList) {
            wtRiskList = data.wtRiskList.filter(e => e.checkFlag == 1).map(e => e.optionCode);
            wtRiskList.forEach(i => {
              if (i == "other") {
                this.wtRiskThow = false
              }
            })
          }
          if (data.wtRiskList) {
            var wtRiskOptionName = data.wtRiskList.filter(e => e.optionCode == 'other').map(e => e.optionName);
            if (wtRiskOptionName != "" && wtRiskOptionName != "其他") {
              this.wtRiskOther = wtRiskOptionName
            }
          }
          this.dataForm.wtRiskList = wtRiskList
          this.wtUserList = this.dataForm.wtUserList
          this.wtSecurityPreventionList = this.dataForm.wtSecurityPreventionList

          this.safetySupervisorList = this.dataForm.safetySupervisorList;
          this.securityDisclosureList = this.dataForm.securityDisclosureList;
          if (this.$refs.superviseImg && this.dataForm.supervision) {
            this.$refs.superviseImg.superviseData.supervision =
              this.dataForm.supervision;
          }
          if (this.$refs.finishWorkImg && this.dataForm.completion) {
            this.$refs.finishWorkImg.finishWorkData.completion =
              this.dataForm.completion;
          }

          this.$refs.securityDisclosure.initData(this.dataForm.wtDisclosureList)
          if (this.type == 'view') {
            this.wtRiskThow = true
          }
          resolve(this.dataForm);
          // if (this.type === 'sign') {
          //   this.businessStatus = this.$route.query.businessStatus;
          // }
        })
      })
    },
    // 获取作业票编号
    getTemplateCode() {
      templateCode().then((res) => {
        if (res.data.code == 200) {
          this.dataForm.ticketCode = res.data.data;
        }
      })
    },
    getUserList(data) {
      if (!this.operatorSelect) {
        let userList = []
        data.selectAllData.forEach(item => {
          userList.push({
            userId: item.id.toString(),
            realName: item.realName,
            userDept: item.deptId
          })
        })
        let cc = {
          ticketCode: this.dataForm.ticketCode,
          userList: userList
        }
        this.$loading()
        wtBaseHoistRetransmission(cc).then(res => {
          this.$message({
            type: "success",
            message: "操作成功",
            duration: 1000,
          });
          this.userListShow = false;
        }).finally(() => {
          this.$loading().close()
        })
      } else {
        let userList = []
        if (this.userOpentype == "userName") {
          //作业人员选择逻辑
          data.selectAllData.forEach(item => {
            userList.push({
              ticketCode: this.dataForm.ticketCode,
              userId: item.id,
              userAccount: item.account,
              userName: item.realName,
              userLicense: item.userLicense,
              userType: this.userOpentype,
              userDept: item.deptName
            })
          })
          this.wtUserList = userList;
        } else {
          if (this.userOpentype == 'supervise') {
            data.selectAllData.forEach((item) => {
              userList.push({
                ticketCode: this.dataForm.ticketCode,
                conservatorId: item.id,
                conservatorName: item.realName,
                conservatorDept: item.deptId
              });
            });
            this.safetySupervisorList = userList;
          }
          if (this.userOpentype == 'examPeople') {
            data.selectAllData.forEach((item) => {
              userList.push({
                name: item.realName,
                id: item.id
              });
            });
            this.examPeopleList = userList;
          }
          if (this.userOpentype == 'examManage') {
            data.selectAllData.forEach((item) => {
              userList.push({
                name: item.realName,
                id: item.id
              });
            });
            this.examManageList = userList;
          }
        }
      }
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 获取数据字段
    getDictory() {
      // 吊装级别
      getDictionary({
        code: 'hoist_level_type'
      }).then((res) => {
        this.hoistLevelOption = res.data.data;
      })
      // 安全检查
      security().then((res) => {
        if (res.data.code == 200) {
          this.securityCheckOption = res.data.data;
        }
      })
      if (this.type == 'add') {
        // 风险辨识结果
        wtRiskAll("dzzy").then((res) => {
          if (res.data.code == 200) {
            this.riskIdentResults = res.data.data;
          }
        })
        // 安全措施
        wtSecurityPreventionAll("dzzy").then((res) => {
          if (res.data.code == 200) {
            this.wtSecurityPreventionList = res.data.data;
          }
        })
      }
    },
    // 打开当前用户所在部门及以下弹窗
    openDeptBelow(type) {
      this.deptOpenType = type;
      this.deptBelowShow = true;
    },
    // 选择部门
    getDeptBelow(data) {
      this.deptBelowShow = false;
      if (this.deptOpenType == 'requirOrg') {
        this.dataForm.requirOrg = data.deptName;
      }
      if (this.deptOpenType == 'workOrg') {
        this.dataForm.workOrg = data.deptName;
      }
    },
    //关键的其他特殊作业及安全作业票号:
    // relatedWt() {
    //   this.jobTicketDialogShow = true
    // },
    // 打开人员选择弹窗
    openUser(type) {
      this.userOpentype = type;
      this.userShow = true;
    },
    // 选择人员
    getUser(data) {
      this.userShow = false;
      if (this.userOpentype == 'solicitorUserName') {
        this.dataForm.solicitorUserId = data.userId;
        this.dataForm.solicitorUserName = data.userName;
        this.dataForm.solicitorNickName = data.nickName;
      }
      if (this.userOpentype == 'userName') {
        this.dataForm.userId = data.userId;
        this.dataForm.userName = data.realName;
        this.dataForm.userCode = data.code;
      }
      if (this.userOpentype == 'conductorUserName') {
        this.dataForm.conductorUserId = data.userId;
        this.dataForm.conductorUserName = data.userName;
        this.dataForm.conductorNickName = data.nickName;
      }
      if (this.userOpentype == 'guardianName') {
        this.dataForm.guardianId = data.userId;
        this.dataForm.guardianName = data.userName;
        this.dataForm.guardianCode = data.code;
      }
      if (this.userOpentype == "securityDisclosureName") {
        this.dataForm.securityDisclosureCode = data.code;
        this.dataForm.securityDisclosureId = data.userId;
        this.dataForm.securityDisclosureName = data.userName;
        this.dataForm.securityDisclosureDept = data.deptId;
      }
      if (this.userOpentype == "disclosureName") {
        this.discloseForm.disclosureName = data.userName;
        this.discloseForm.disclosureId = data.userId;
      }
    },
    /**
     * 关键的其他特殊作业及安全作业票号选中后的数据
     */
    // submitData(row) {
    //   this.dataForm.relatedWt = row.ticketCode;
    //   this.jobTicketDialogShow = false
    // },
    // 转发
    async handleForward() {

      await this.headSave("save")
      this.operatorSelect = false;
      this.userListShow = true;
      this.$nextTick(() => {
        this.$refs.userDeptMultipleDialog.selectionClear();
      })
    },
    // 保存
    async headSave(type = 'save') {
      if (this.wtUserList.length == 0) {
        this.$message.warning('请先选择作业人员!');
        return;
      }
      if (this.safetySupervisorList.length == 0) {
        this.$message.warning("请先选择安全监管人员!");
        return;
      }
      if (this.businessStatus == 'DISCLOSED' && this.securityDisclosureList.length == 0) {
        this.$message.warning("安全交底不能为空!");
        return;
      }
      if (this.businessStatus == 'SUPERVISE' && this.$refs.superviseImg.superviseData.supervision == '') {
        this.$message.warning("现场照片不能为空!");
        return;
      }
      if (this.businessStatus == 'CHECKACCEPT' && this.$refs.finishWorkImg.finishWorkData.completion == '') {
        this.$message.warning("完工验收照片不能为空!");
        return;
      }
      let emptywtSecurity = this.wtSecurityPreventionList.filter((item) => {
        if (item.securityContent == '') {
          return item
        }
      })
      if (!this.dataForm.measureSignatureUrl) {
        this.$message.warning("安全措施签名不能为空!");
        return;
      }
      if (emptywtSecurity.length != 0) {
        this.$message.warning('请完善安全措施数据!');
        return;
      }
      let data = JSON.parse(JSON.stringify(this.dataForm))
      // 风险辨识
      let yesRiskIdent = this.dataForm.wtRiskList.map(item => this.riskIdentResults.find(x => x.optionCode === item));
      yesRiskIdent.forEach((item) => {
        if (item) {
          item.checkFlag = 1;
        }
      })
      let noRiskIdent = this.riskIdentResults.filter(item => !this.dataForm.wtRiskList.includes(item.optionCode));
      noRiskIdent.forEach((item) => {
        if (item) {
          item.checkFlag = 2;
        }
      })
      let wtRiskList = [...yesRiskIdent, ...noRiskIdent]
      if (this.dataForm.timeInterval) {
        data.startTime = this.dataForm.timeInterval[0];
        data.endTime = this.dataForm.timeInterval[1];
      }

      let examPeopleId = [];
      let examPeopleName = [];
      this.examPeopleList.forEach((item) => {
        examPeopleId.push(item.id);
        examPeopleName.push(item.name);
      })
      let examManageId = [];
      let examManageName = [];
      this.examManageList.forEach((item) => {
        examManageId.push(item.id);
        examManageName.push(item.name);
      })
      data.examPeopleId = examPeopleId.join(',');
      data.examPeopleName = examPeopleName.join(',');
      data.examManageId = examManageId.join(',');
      data.examManageName = examManageName.join(',');

      data.orgId = this.userInfo.dept_id
      data.wtRiskList = wtRiskList;
      data.wtUserList = this.wtUserList;
      data.wtSecurityPreventionList = this.wtSecurityPreventionList;

      data.safetySupervisorList = this.safetySupervisorList;
      data.securityDisclosureList = this.securityDisclosureList;
      if (this.$refs.superviseImg) {
        data.supervision = this.$refs.superviseImg.superviseData.supervision;
      }
      if (this.$refs.finishWorkImg) {
        data.completion = this.$refs.finishWorkImg.finishWorkData.completion;
      }

      data.wtDisclosureList = []
      var submit = this.$refs.securityDisclosure.submit();
      if (submit.secure) {
        data.wtDisclosureList.push({
          id: submit.id,
          userSign: submit.secure,
          userType: "secure"
        })
      }
      submit.receive.forEach(e => {
        data.wtDisclosureList.push({
          id: e.id,
          userSign: e.imgUrl,
          userType: "receive"
        })
      })
      data.isSubmit = type
      return new Promise((resolve, reject) => {
        this.$refs.formLayout.validate((valid) => {
          if (valid) {
            this.$loading()
            wtBaseHoist(data).then((res) => {
              if (res.data.code == 200) {
                this.subtableId = res.data.data.id
                this.initData(res.data.data.ticketCode).then((indata) => {
                  resolve(indata);
                });
                if (type != "submit" && type == 'saveData') {
                  this.$message.success("保存成功")
                }
                ;
                if (type === 'submit') {
                  this.$message.success('提交成功')
                  this.headCancel()
                }
              }
            }).finally(() => {
              this.$loading().close()
            })
          }
        })
      })
    },

    // 打开签名弹窗
    openSignature(index, tableName) {
      this.signatureShow = true;
      this.tableIndex = index;
      this.tableName = tableName;
    },
    // 新增安全措施
    addSecure() {
      this.wtSecurityPreventionList.push({
        securityContent: '',
        invovedFlag: '0',
        confirmUser: '',
      })
    },
    // 删除安全措施
    deleteSecure(index, rows) {
      if (rows[index].id && rows[index].id > 0) {
        this.$loading()
        removeWtSecurityPrevention(rows[index].id).then((res) => {
          rows.splice(index, 1);
          this.$message({
            type: "success",
            message: res.data.msg,
            duration: 1000,
          });
        }).finally(() => {
          this.$loading().close()
        });
      } else {
        rows.splice(index, 1);
        this.$message({
          type: "success",
          message: "操作成功",
          duration: 1000,
        });
      }

    },
    // 列表赋值签名图片
    getSignatureImg(imgUrl) {
      if (this.tableName == 'recognizePeople') {
        this.dataForm.recognizePeople = imgUrl;
        console.log("this.dataForm.recognizePeople", this.dataForm.recognizePeople)
      }
      if (this.tableName == 'wtSecurityPreventionList') {
        this.wtSecurityPreventionList[this.tableIndex].confirmUser = imgUrl;
      }
      this.signatureShow = false;
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-step__title {
  cursor: pointer;
}
.ticketMain {
  width: 100%;
  height: 100%;
  display: flex;
}

.ticketLeft {
  width: 145px;
  height: 100%;
  border-right: 1px solid #cccccc;
  background-color: #fff;
}

.ticketLeft ::v-deep .head-layout {
  height: 46px;
  line-height: 46px;
}

.ticketRight ::v-deep .head-layout {
  height: 46px;
  line-height: 46px;
}

.ticketStep {
  width: calc(100% - 40px);
  height: 300px;
  padding: 20px;
}

.ticketRight {
  width: calc(100% - 146px);
  height: 100%;
}

.ticketContent {
  width: 100%;
  height: calc(100% - 46px);
  overflow: auto;
  scrollbar-width: none;
}

::v-deep .el-input-number .el-input__inner {
  text-align: left !important;
}

.ticketHead {
  position: relative;
}

.ticketHeadBtn {
  position: absolute;
  top: 7px;
  left: 150px;
}

.ticketHeadCode {
  position: absolute;
  top: 7px;
  left: 400px;
}

.ticketForm {
  padding: 12px;
  background-color: #FFFFFF;
}

.ticketGas {
  margin-top: 12px;
  background-color: #FFFFFF;
}

.gasTable {
  padding: 12px;
}

.ticketSecure {
  margin-top: 12px;
  background-color: #FFFFFF;
}

.secureTable {
  padding: 12px;
}

.ticketDisclose {
  margin-top: 12px;
  background-color: #FFFFFF;
}

.signatureImg {
  width: 70px;
  height: 32px;
  display: block;
  margin: auto;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

::v-deep .el-textarea .el-input__count {
  height: 20px !important;
}

.riskAreaClass ::v-deep .common_tree_handle {
  height: 100% !important;
}

.riskAreaClass ::v-deep .common_tree_handle .el-tree {
  height: calc(100% - 110px) !important;
}
</style>
